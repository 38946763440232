var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "card" }, [
      _c("div", { staticClass: "card-body form-card" }, [
        _c("div", { staticClass: "row" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "col-md-10" },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                class: _vm.errors.has("name") ? "border-danger" : "",
                attrs: {
                  placeholder: "Nhập rule tiếng việt",
                  "data-vv-name": "name",
                  "data-vv-as": "Tên",
                },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
              _vm.errors.has("name")
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.errors.first("name"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "row" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "col-md-10" },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                class: _vm.errors.has("name_en") ? "border-danger" : "",
                attrs: {
                  placeholder: "Nhập rule tiếng anh",
                  "data-vv-name": "name_en",
                  "data-vv-as": "Tên",
                },
                model: {
                  value: _vm.form.name_en,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name_en", $$v)
                  },
                  expression: "form.name_en",
                },
              }),
              _vm.errors.has("name_en")
                ? _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.errors.first("name_en"))),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "div",
        { staticClass: "page-action" },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("Quay lại")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("Cập nhật")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-2" }, [
      _c("b", { staticClass: "required" }, [_vm._v("Tiếng việt")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-2" }, [
      _c("b", { staticClass: "required" }, [_vm._v("Tiếng anh")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }