<template lang="html">
  <div>
    <div class="card">
      <div class="card-body form-card">
        <div class="row">
          <div class="col-md-2">
            <b class="required">Tiếng việt</b>
          </div>
          <div class="col-md-10">
            <el-input
              placeholder="Nhập rule tiếng việt"
              v-model="form.name"
              :class="errors.has('name')?'border-danger':''"
              v-validate="'required'"
              data-vv-name="name"
              data-vv-as="Tên"
            ></el-input>
            <span class="text-danger" v-if="errors.has('name')">{{ errors.first('name') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <b class="required">Tiếng anh</b>
          </div>
          <div class="col-md-10">
            <el-input
              placeholder="Nhập rule tiếng anh"
              v-model="form.name_en"
              :class="errors.has('name_en')?'border-danger':''"
              v-validate="'required'"
              data-vv-name="name_en"
              data-vv-as="Tên"
            ></el-input>
            <span class="text-danger" v-if="errors.has('name_en')">{{ errors.first('name_en') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Cập nhật</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { Input, Button, Message } from 'element-ui';
  import { mapState } from 'vuex'

  export default {
    components: {
      ElInput: Input,
      ElButton: Button,
      Message
    },
    data () {
      return {
        form: {},
      }
    },

    computed: mapState(['ruleDetail']),

    mounted() {
      let id = this.$route.params.id;
      this.$store.dispatch('fetchRuleDetail', id);
      this.$store.dispatch('setPageTitle', 'Chỉnh sửa');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Cập nhật',
        type: 'primary',
        icon: '',
        callback: this.save
      }])
    },

    methods: {
      cancel() {
        this.$router.push({name: 'AllRules'});
      },
      save() {
        let self = this;
        this.$validator.validateAll().then(function (result) {
          if (result) {
            self.$store.dispatch('updateRule', self.form);
            Message({
              message: 'Cập nhật thành công',
              type: 'success'
            });
          }
        })
      }
    },
    watch: {
      ruleDetail(newVal, oldVal) {
        this.form = newVal;
      },
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
      this.$store.state.ruleDetail = [];
    }
  }
</script>
